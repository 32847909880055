'use strict';

/* eslint-disable no-underscore-dangle */

var gtm = {
    url: '',
    getPageAction: function () {
        return $('.page').data('action');
    },
    requestProductDetails: function (data, targetData, method) {
        $.ajax({
            url: gtm.url,
            method: method,
            data: data,
            dataType: 'json',
            success: function (response) {
                gtm.processResponse(response.productDetails, targetData);
            },
            error: function () {
                console.log('Error in getting GTM data');
            }
        });
    },
    getProductIDS: function (elem) {
        var details = elem;
        var pids = [];
        details.each(function (i) {
            var pid = $(this).data('pid');
            pids.push(pid);
        });

        if (pids.length >= 0) {
            pids = pids.join(',');
        }

        return pids;
    },
    processResponse: function (response, targetData, targetKey) {
        try {
            if (response.length > 0) {
                var currTargetData = gtm.targetData[targetData];
                var evn = currTargetData.data.event;

                var targetObj = Object.create({});

                // deep clone target object
                $.extend(true, targetObj, currTargetData.data);

                if (typeof (response) === 'string') {
                    // append string response in target property
                    if (typeof (targetKey) !== 'undefined') {
                        gtm.getProperty(targetObj, currTargetData.keys)[targetKey] = response;
                    }
                } else {
                    // push product details
                    response.forEach(function (v) {
                        // clean product details
                        var detail = v;

                        // check if raw product details
                        if ('productName' in v) {
                            detail = productDetail(v);
                        }

                        gtm.getProperty(targetObj, currTargetData.keys).push(detail);
                    });
                }

                dataLayer.push(targetObj);
            }
        } catch (e) {
            console.log('GTM: process response error');
        }
    },
    init: function () {
        gtm.action.onload();
        var events = gtm.action.events;
        Object.keys(events).forEach(function (key) {
            if (typeof events[key] === 'function') {
                events[key]();
            }
        });
    },
    getProperty: getProperty, // function coming from backend
    targetData: gtmSchema, // object coming from backend
    ajaxHook: function (callback) {
        $(document).ajaxComplete(function (e, request, settings) {
            var response = JSON.parse(request.responseText);
            callback(response);
            $(e.currentTarget).off('ajaxComplete');
            $('button.gtm-disabled').removeClass('gtm-disabled');
        });
        $(document).ajaxError(function (e, request, settings) {
            console.log('Error in getting GTM data');
            $(e.currentTarget).off('ajaxError');
        });
        return false;
    },
    action: {
        onload: function () {
            // add some onload script here
        },
        events: {
            // add all event listener triggered thru click

            newsletterSignup: function () {
                $('body').on('submit', '#newsletter-signup', function (e) {
                    var email = $('#dwfrm_mcsubscribe_email').val();

                    var data = {
                        event: 'signup.newsletter.footer',
                        email: email
                    };

                    dataLayer.push(data);
                });
            },

            productListClick: function () {
                $('body').on('click', '.product-tile__wrapper a:first-of-type', function (e) {
                    var plpData = $(this).data('gtmplp');
                    if (plpData && plpData.id) {
                        var category = plpData.category || 'unknown';
                        var data = {
                            event: 'ecom.product.click',
                            ecommerce: {
                                click: {
                                    actionField: {list: category},
                                    products: []
                                }
                            }
                        };
                        data.ecommerce.click.products.push(plpData);
                        dataLayer.push(data);
                    }
                });
            },
            showMore: function () {
                $('.container').on('click', '.show-more button', function (e) {
                    e.stopPropagation();
                    var showMoreUrl = $(this).data('url');
                    e.preventDefault();

                    var queryStr = showMoreUrl.split('?')[1];
                    gtm.url = gtmUrls.searchDetails + '?' + queryStr;
                    gtm.requestProductDetails({}, 'PLP', 'GET');
                });
            },
            addToCart: function () {
                var selectedVariantId = '';
                /**
                 * Create additional listener to existing add to cart function.
                 */
                $('body').on('product:beforeAddToCart', function (e, data) {
                    selectedVariantId = $(data).data('vid');
                    if (!selectedVariantId || typeof selectedVariantId === 'undefined' || selectedVariantId === undefined || selectedVariantId === '') {
                        selectedVariantId = $('.product-detail').data('vid');
                        if (!selectedVariantId) {
                            selectedVariantId = $(data).data('pid');
                        }
                    }
                    // var selectedVariantElem = $('.product-detail[data-pid="' + selectedVariantId + '"]');

                    // check if not bundle
                    // if (selectedVariantElem.find('.product-bundle').length === 0 &&
                    //         selectedVariantElem.find('.bundle-items').length === 0) {
                    //     selectedVariantId = selectedVariantElem.find('.product-id').text();
                    // }
                });

                $('body').on('product:afterAddToCart', function (e, data) {
                    var items = data.cart.items;
                    var productDetails = [];

                    items.forEach(function (item) {
                        // attach selected item
                        var detail = productDetail(item, null, 1);
                        detail.id = $('.product-detail').data('analytics-id');
                        if (!detail.id || typeof detail.id === 'undefined' || detail.id === undefined || detail.id === '') {
                            detail.id = item.id;
                        }
                        if (detail.variant === selectedVariantId) {
                            productDetails.push(detail);
                        }
                    });
                    gtm.processResponse(productDetails, 'AddToCart');
                });
            },
            removeLineItemfromCart: function () {
                $('body').on('click', '.cart-delete-confirmation-btn', function (e) {
                    e.preventDefault();
                    var pid = $(this).data('pid');
                    gtm.ajaxHook(function (req) {
                        gtm.url = gtmUrls.productDetails;
                        gtm.requestProductDetails({ pids: pid }, 'RemoveLineItemfromCart', 'POST');
                    });
                });
            },
            cartUpdateQty: function () {
                $('body').on('change', '.quantity-form > .quantity', function () {
                    var _this = $(this);
                    var preQty = _this.attr('data-pre-select-qty');
                    var newQty = _this.val();
                    var pid = _this.data('pid');

                    var target = 'CartIncreaseQty';
                    if (preQty > newQty) {
                        target = 'CartDecreaseQty';
                    }

                    gtm.ajaxHook(function (response) {
                        var items = response.items;
                        var productDetails = [];

                        items.forEach(function (item) {
                            if (item.id === pid) {
                                var detail = productDetail(item);
                                productDetails.push(detail);
                            }
                        });

                        gtm.processResponse(productDetails, target);

                        _this.attr('data-pre-select-qty', newQty);
                    });
                });
            },
            checkout: function () {
                // edit sections btn
                $('.customer-summary .edit-button').on('click', function (e) {
                    e.preventDefault();

                    gtm.processResponse('Pre-auth', 'CheckoutLogin', 'option');
                });

                $('.shipping-summary .edit-button').on('click', function (e) {
                    e.preventDefault();

                    var shippingOpt = $('.shipping-method__name .form-check-input:checked').val();
                    gtm.processResponse(shippingOpt, 'ShippingOption', 'option');
                });

                $('.payment-summary .edit-button').on('click', function (e) {
                    e.preventDefault();

                    var paymentOpt = $('.payment-options .active').closest('[data-method-id]').data('method-id');
                    gtm.processResponse(paymentOpt, 'PaymentOption', 'option');
                });

                // submit sections
                $('button.submit-customer, button.submit-customer-login').on('click', function (e) {
                    e.preventDefault();

                    gtm.ajaxHook(function (response) {
                        gtm.processResponse(response.order.items.items, 'Shipping');
                    });
                });

                $('button.submit-shipping').click(function (e) {
                    e.preventDefault();

                    var $btn = $('.submit-shipping');
                    var isEnabled = !$btn.is('.gtm-disabled');

                    if (isEnabled) {
                        $btn.addClass('gtm-disabled');
                        gtm.ajaxHook(function (response) {
                            gtm.processResponse(response.shippingMethod, 'ShippingOption', 'option');
                            gtm.processResponse(response.order.items.items, 'Payment');
                        });
                    }
                });

                $('button.submit-payment').click(function (e) {
                    e.preventDefault();

                    var $btn = $('.submit-shipping');
                    var isEnabled = !$btn.is('.gtm-disabled');

                    if (isEnabled) {
                        $btn.addClass('gtm-disabled');
                        gtm.ajaxHook(function (response) {
                            if (!response.error) {
                                gtm.processResponse(response.paymentMethod.value, 'PaymentOption', 'option');
                                gtm.processResponse(response.order.items.items, 'OrderReview');
                            }
                        });
                    }
                });
            }
        } // end
    }
};

window.addEventListener('load', function () {
    gtm.init();
});
